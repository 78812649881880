import request from '@/utils/request'


// 查询行业信息列表
export function listProfession(query) {
  return request({
    url: '/biz/con-profession/list',
    method: 'get',
    params: query
  })
}

// 查询行业信息分页
export function pageProfession(query) {
  return request({
    url: '/biz/con-profession/page',
    method: 'get',
    params: query
  })
}

// 查询行业信息详细
export function getProfession(data) {
  return request({
    url: '/biz/con-profession/detail',
    method: 'get',
    params: data
  })
}

// 新增行业信息
export function addProfession(data) {
  return request({
    url: '/biz/con-profession/add',
    method: 'post',
    data: data
  })
}

// 修改行业信息
export function updateProfession(data) {
  return request({
    url: '/biz/con-profession/edit',
    method: 'post',
    data: data
  })
}

// 删除行业信息
export function delProfession(data) {
  return request({
    url: '/biz/con-profession/delete',
    method: 'post',
    data: data
  })
}
